// Depedencies
import React from 'react';
// Assets
import './styles/App.css';
// components
import Perfil from './pages/Perfil';

const App = () => {
  return ( 
    <Perfil/>
   );
}
 
export default App;
